import {
  Box,
  chakra,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Card,
  Text,
  Icon,
} from "@chakra-ui/react";
import BarChart from "./chart/bar";
import {
  barChartDataDailyTraffic,
  barChartOptionsDailyTraffic,
} from "./chart/data";
import * as AuthController from "../utils/auth";

import { BsPerson } from "react-icons/bs";
import { FiServer } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { RiArrowUpSFill } from "react-icons/ri";
import Showcase from "../components/chart/showCase";
import { useEffect } from "react";
import { useState } from "react";

function StatsCard(props) {
  const { title, stat, icon } = props;
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={"5"}
      shadow={"xl"}
      border={"1px solid"}
      borderColor={useColorModeValue("gray.800", "gray.500")}
      rounded={"lg"}
    >
      <Flex justifyContent={"space-between"}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel fontWeight={"medium"} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize={"2xl"} fontWeight={"medium"}>
            {stat}
          </StatNumber>
        </Box>
        <Box
          my={"auto"}
          color={useColorModeValue("gray.800", "gray.200")}
          alignContent={"center"}
        >
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
}

export default function BasicStatistics() {
  const [totalUser, setTotalUser] = useState();
  const [traffic, setTraffic] = useState();
  const [users, setUsers] = useState();
  useEffect(() => {
    AuthController.getTotalUsers().then((res) => {
      setTotalUser(res);
    });
    AuthController.getDailyTraffic().then((res) => {
      setTraffic(res);
    });
    AuthController.getAllUser().then((res) => {
      setUsers(res);
    });
  }, []);

  console.log("users", users);
  return (
    <Box maxW="7xl" mx={"auto"} pt={2} px={{ base: 2, sm: 12, md: 17 }}>
      <chakra.h1
        textAlign={"center"}
        fontSize={"4xl"}
        py={4}
        fontWeight={"bold"}
      >
        Our company is expanding, you could be too.
      </chakra.h1>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
        <StatsCard
          title={"Users"}
          stat={totalUser?.no_of_users || 0}
          icon={<BsPerson size={"3em"} />}
        />
        <StatsCard
          title={"Servers"}
          stat={"1,000"}
          icon={<FiServer size={"3em"} />}
        />
        <StatsCard
          title={"Datacenters"}
          stat={"7"}
          icon={<GoLocation size={"3em"} />}
        />
      </SimpleGrid>
      <Showcase>
        <Card p="1rem" mt="50px" align="center" direction="column" w="100%">
          <Flex
            width={"100%"}
            justify="space-between"
            align="start"
            px="20px"
            py="5px"
          >
            <Flex flexDirection="column" align="start" me="20px">
              <Text color="secondaryGray.600" fontSize="sm" fontWeight="500">
                Daily Traffic
              </Text>
              <Flex align="end">
                <Text
                  // color={textColor}
                  fontSize="34px"
                  fontWeight="700"
                  lineHeight="100%"
                >
                  {traffic?.daily_traffic || 0}
                </Text>
                <Text
                  ms="6px"
                  color="secondaryGray.600"
                  fontSize="sm"
                  fontWeight="500"
                >
                  Visitors
                </Text>
              </Flex>
            </Flex>
            <Flex align="center">
              <Icon as={RiArrowUpSFill} color="green.500" />
              <Text color="green.500" fontSize="sm" fontWeight="700">
                +2.45%
              </Text>
            </Flex>
          </Flex>
          <Box h="240px" mt="auto">
            <BarChart
              chartData={barChartDataDailyTraffic}
              chartOptions={barChartOptionsDailyTraffic}
            />
          </Box>
        </Card>
      </Showcase>
    </Box>
  );
}
