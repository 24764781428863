import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router";
import { getItem } from "../utils/localstorage";

export const UnprotectedRoute = ({ children }) => {
  const user = getItem("token");

  const isUserLoading = useSelector((state) => state.userReducer?.isLoading);

  const authRoute = "/dashboard";

  if (!user && !isUserLoading) {
    return children;
  }

  if (user && !isUserLoading) {
    return <Navigate to={authRoute} replace />;
  }

  return <>Loading</>;
};
