import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  //   TableCaption,
  TableContainer,
} from "@chakra-ui/react";

const UserTable = (props) => {
  let { users } = props;

  return (
    <TableContainer>
      <Table variant="simple">
        {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
        <Thead>
          <Tr>
            <Th>Email</Th>
            <Th>Fullname</Th>
            <Th>phone</Th>
            <Th>visits</Th>
            <Th>Zip</Th>
            <Th>country</Th>
            <Th>billing_address</Th>
            <Th isNumeric>Quota</Th>
          </Tr>
        </Thead>
        <Tbody>
          {users?.length > 0 &&
            users?.map((sin, ind) => (
              <Tr key={ind}>
                <Td>{sin.email}</Td>
                <Td>{sin.fullname}</Td>
                <Td>{sin.phone}</Td>
                <Td>{sin.visits}</Td>
                <Td>{sin.zip}</Td>
                <Td>{sin.country}</Td>
                <Td>{sin.billing_address}</Td>
                <Td>{sin.quota}</Td>
              </Tr>
            ))}
        </Tbody>
        <Tfoot>
          <Tr>
            {/* <Th>To convert</Th>
            <Th>into</Th>
            <Th isNumeric>multiply by</Th> */}
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

export default UserTable;
