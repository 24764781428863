import { UserReduxActionType } from "../actions/types";
import { getItem } from "../../utils/localstorage";

const initState = {
  // user: getItem("user"),
  token: getItem("token"),
  isLoading: true,
};

const userReducer = (state = initState, action) => {
  if (action.type === UserReduxActionType.INIT_USER) {
    return {
      ...state,
      user: action.payload,
      isLoading: false,
    };
  }

  if (action.type === UserReduxActionType.ADD_USER) {
    return {
      ...state,
      user: action.payload,
    };
  }

  if (action.type === UserReduxActionType.REMOVE_USER) {
    return {
      ...state,
      user: null,
    };
  }

  return state;
};

export default userReducer;
