import React from "react";
// import { useSelector } from "react-redux";
import { getItem } from "../utils/localstorage";
import { Navigate } from "react-router";

export const ProtectedRoute = ({ children }) => {
  // const token = useSelector((state) => state.userReducer?.user?.token);
  const token = getItem("token");

  if (!token) {
    return <Navigate to="/" replace />;
  }

  if (token) {
    return children;
  }
};
