export const setItem = (key, value) => {
  localStorage.setItem(key, value);
};

export const getItem = (key, isJSON = true) => {
  // let item = console.log("item", item);
  // if (item && isJSON) {
  //   return item;
  // }
  return localStorage.getItem(key || "token");
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};

export const getParams = (url) => {
  const params = {};
  if (typeof document !== "undefined") {
    const parser = document.createElement("a");
    parser.href = url;
    const query = parser.search.substring(1);
    const vars = query.split("&");
    if (vars[0] !== "") {
      for (let i = 0; i < vars.length; i += 1) {
        const pair = vars[i].split("=");
        params[pair[0]] = decodeURIComponent(pair[1]);
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
  return params;
};
