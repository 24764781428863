import { Flex, Box, Heading, useColorModeValue } from "@chakra-ui/react";

import { useState } from "react";
import Table from "../components/table";

import * as AuthController from "../utils/auth";
import { useEffect } from "react";

export default function Userdata() {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    AuthController.getAllUser().then((response) => {
      setUsers(response?.Users);
    });
  }, []);

  return (
    <Box bg={useColorModeValue("gray.50", "gray.800")}>
      <Flex minH={"10vh"} align={"center"} justify={"center"}>
        <Heading fontSize={"4xl"}> Users</Heading>
      </Flex>
      <Table users={users} />
    </Box>
  );
}
