import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { routeNames } from "../config/routes";
import MainHome from "../components/home";
import Dashboard from "../pages/dashboard";
import NotFound from "../pages/ErrorPage";

import { ProtectedRoute } from "./ProtectedRoute";
import { UnprotectedRoute } from "./UnprotectedRoute";

import SignIn from "../pages/login";
import UserSignIn from "../pages/userPages/login";
import UserSignUp from "../pages/userPages/signup";
import ForgetPass from "../pages/forgetPassword";
import AddAdmin from "../pages/addAdmin";
import GetGeoData from "../pages/getGeoData";
import Userdata from "../pages/userData";

const MainRouter = () => {
  return (
    <Routes>
      <Route
        path={routeNames.signIn()}
        element={
          <UnprotectedRoute>
            <SignIn />
          </UnprotectedRoute>
        }
      />
      <Route
        path={routeNames.UserSignIn()}
        element={
          <UnprotectedRoute>
            <UserSignIn />
          </UnprotectedRoute>
        }
      />
      <Route
        path={routeNames.UserSignUp()}
        element={
          <UnprotectedRoute>
            <UserSignUp />
          </UnprotectedRoute>
        }
      />

      <Route path="*" element={<NotFound />} />
      <Route
        path={routeNames.dashboard()}
        element={
          <ProtectedRoute>
            <Dashboard>
              <MainHome />
            </Dashboard>
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="/dashboard/statistic"
        element={
          <ProtectedRoute>
            <Dashboard>
              <MainHome />
            </Dashboard>
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/update-pass"
        element={
          <ProtectedRoute>
            <Dashboard>
              <ForgetPass />
            </Dashboard>
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/users"
        element={
          <ProtectedRoute>
            <Dashboard>
              <Userdata />
            </Dashboard>
          </ProtectedRoute>
        }
      />
      <Route
        path={routeNames.addAdmin()}
        element={
          <ProtectedRoute>
            <Dashboard>
              <AddAdmin />
            </Dashboard>
          </ProtectedRoute>
        }
      />
      <Route
        path="/dashboard/get-geo-data"
        element={
          <ProtectedRoute>
            <Dashboard>
              <GetGeoData />
            </Dashboard>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default MainRouter;
