import { baseAxiosInstance } from "../config/axios";
import { setItem } from "./localstorage";
import store from "../store/index";

export const login = ({ body }) => {
  return baseAxiosInstance
    .post("/admin/login", body, {
      headers: {
        withCredentials: true,
        crossDomain: true,
      },
    })
    .then((response) => {
      setItem("token", response.data.message);
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const updatePass = ({ body }) => {
  let token = store.getState().userReducer?.token || "";
  return baseAxiosInstance
    .post("/admin/updatePass", body, {
      headers: {
        auth: `${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};
export const addAdmin = ({ body }) => {
  let token = store.getState().userReducer?.token || "";
  return baseAxiosInstance
    .post("admin/signup", body, {
      headers: {
        auth: `${token}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err;
    });
};

export const getGeoData = (zipCode) => {
  return baseAxiosInstance
    .get(`/geoData/US/${zipCode}`, {
      headers: { "x-api-key": `${`zp-3QUPktwRPTvciZeWkihM4s0IAo17wA`}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getAdmin = () => {
  let user = store.getState().userReducer || "";

  return baseAxiosInstance
    .get("/admin", {
      headers: {
        auth: `${user?.token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const getTotalUsers = () => {
  let user = store.getState().userReducer || "";
  return baseAxiosInstance
    .get("/admin/getTotalUsers", {
      headers: {
        auth: `${user?.token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const getDailyTraffic = () => {
  let token = store.getState().userReducer?.token || "";

  return baseAxiosInstance
    .get("/admin/getDailyTraffic", {
      headers: {
        auth: `${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const getAllUser = () => {
  let token = store.getState().userReducer?.token || "";

  return baseAxiosInstance
    .get("/admin/getAllUsersData", {
      headers: {
        auth: `${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};
export const logout = () => {
  let token = store.getState().userReducer?.token || "";

  return baseAxiosInstance
    .get("/admin/logout", {
      headers: {
        auth: `${token}`,
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      throw err;
    });
};
