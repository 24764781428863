// Chakra imports
import { Box } from "@chakra-ui/react";

import React from "react";
export default function HoursSpent(props) {
  const { children, ...rest } = props;

  return (
    <Box borderRadius={"10px"} backgroundColor={"#fff"} {...rest}>
      {children}
    </Box>
  );
}
