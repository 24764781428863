import { ChakraProvider } from "@chakra-ui/react";
import "./App.css";

import useInitReduxStore from "./store/useInitReduxStore";
import MainRouter from "./router";

function App() {
  useInitReduxStore();

  return (
    <ChakraProvider>
      {/* <RouterProvider router={router} /> */}
      <MainRouter />
    </ChakraProvider>
  );
}

export default App;
