import { Box } from "@chakra-ui/react";
import SidebarWithHeader from "../components/sidebar";

import React from //  ,{ useEffect, useState }
"react";
// import * as AuthController from "../utils/auth";

const Ddashboard = ({ children }) => {
  return (
    <Box>
      <SidebarWithHeader>{children}</SidebarWithHeader>
    </Box>
  );
};

export default Ddashboard;
