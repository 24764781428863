import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { UserReduxAction } from "./actions";

const useInitReduxStore = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UserReduxAction.initUser());
  }, [dispatch]);
};

export default useInitReduxStore;
