import { getItem, removeItem } from "../../utils/localstorage";
import { UserReduxActionType } from "./types";

export const UserReduxAction = {
  initUser: () => {
    const user = getItem("user");
    return (dispatch) => {
      dispatch({
        type: UserReduxActionType.INIT_USER,
        payload: user,
      });
    };
  },

  addUser: (user) => {
    // setItem("user", user);
    return (dispatch) => {
      dispatch({
        type: UserReduxActionType.ADD_USER,
        payload: user,
      });
    };
  },

  removeUser: () => {
    removeItem("token");
    return (dispatch) => {
      dispatch({
        type: UserReduxActionType.REMOVE_USER,
        payload: null,
      });
    };
  },
};
