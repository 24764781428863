import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

import { useForm } from "react-hook-form";
import { useState } from "react";

import * as AuthController from "../utils/auth";

export default function GetGeoData() {
  let toast = useToast();
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit } = useForm({});

  const submitHnadler = handleSubmit((values) => {
    setLoading(true);
    AuthController.getGeoData(values.zipCode)
      .then((response) => {
        console.log("response", response);
        toast({
          title: "Success",
          description: response?.data?.message,
          status: "success",
          position: "bottom-right",
        });
        // dispatch(
        //   UserReduxAction.addUser({
        //     token: response.message,
        //   })
        // );
        // navigate("/dashboard");
      })
      .catch((err) => {
        toast({
          title: "Failed",
          description: err?.response?.data?.message,
          status: "error",
          position: "bottom-right",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  });
  return (
    <Flex
      minH={"100vh"}
      // align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack spacing={8} mx={"auto"} maxW={"1000px"} py={4} px={1}>
        <Stack align={"center"}>
          <Heading fontSize={"4xl"}> Get Geo Data</Heading>
        </Stack>
        <Box
          rounded={"lg"}
          bg={useColorModeValue("white", "gray.700")}
          boxShadow={"lg"}
          p={8}
          width={["", "", "700px", "500px"]}
        >
          <form onSubmit={(e) => submitHnadler(e)}>
            <Stack spacing={4}>
              <FormControl id="email">
                <FormLabel>Enter Zip Code</FormLabel>
                <Input {...register("zipCode")} required={true} type="number" />
              </FormControl>

              <Stack spacing={10}>
                <Stack
                  direction={{ base: "column", sm: "row" }}
                  align={"start"}
                  justify={"space-between"}
                >
                  {/* <Checkbox>Remember me</Checkbox> */}
                  <div></div>
                  {/* <Link href="forget-password" color={"blue.400"}>
                    Forgot password?
                  </Link> */}
                </Stack>

                <Button
                  disabled={loading}
                  bg={"blue.400"}
                  color={"white"}
                  _hover={{
                    bg: "blue.500",
                  }}
                  type="submit"
                >
                  submit
                </Button>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Stack>
    </Flex>
  );
}
